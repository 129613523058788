/* @see https://tympanus.net/Development/SectionSeparators/ */
.host {
  background-color: #fff;
  padding: 30px 10%;
  position: relative;
  z-index: 1;
  text-align: center;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translateY(1px);

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media (width > 680px) and (width < 780px) {
  .host {
    padding: 30px 1em;
  }
}

.host.greenTheme {
  background-color: var(--colorTromboli);
  color: white;
  :any-link {
    color: white;
  }
  .delimiterBefore,
  .delimiterAfter {
    color: var(--colorTromboli);
  }
  div,
  h1,
  h2 {
    color: white;
  }
}

.host.whiteTheme {
  background-color: white;
  .delimiterBefore,
  .delimiterAfter {
    color: white;
  }
}

.host.blueTheme {
  background-color: var(--colorCasal);
  color: white;
  .delimiterBefore,
  .delimiterAfter {
    color: var(--colorCasal);
  }
  div,
  h1,
  h2 {
    color: white;
  }
}

.host.brownTheme {
  background-color: var(--colorShingleFawn);
  color: white;
  .delimiterBefore,
  .delimiterAfter {
    color: var(--colorShingleFawn);
  }
  div,
  h1,
  h2 {
    color: white;
  }
}

.delimiterBefore {
  top: -29.5px;
  transform: scaleX(-1);
}

.delimiterAfter {
  bottom: -29.5px;
}

.delimiterBefore,
.delimiterAfter {
  height: 30px;
  position: absolute;
  left: 0;
  width: 100%;
  color: currentColor;

  svg {
    display: block;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}
