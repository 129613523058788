.background {
  background-size: cover;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
}

.loaded {
  animation: sharpen 0.3s ease-in-out both;
}

@keyframes sharpen {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0px);
  }
}
