.bannerTitle {
	text-align: center;
	width: 100%;
	font-family: var(--fontFamilyLondrinaSolid);
	font-weight: 400;
	font-size: 0;
	line-height: 1.1;
	text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.36);
	text-transform: uppercase;
	pointer-events: none;
	overflow: hidden;
	padding: 20px;
}
