.buttonLink,
.button {
  border-radius: 30px;
  border: 0;
  color: var(--colorPrimaryBtnText);
  text-transform: uppercase;
  font-family: var(--fontFamilyLondrinaSolid);
  font-weight: 300;
  letter-spacing: 1.2px;
  padding: 10px 20px 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--colorPrimaryBtnBackground);
}

.button a,
.buttonLink {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.button svg,
.buttonLink svg {
  width: 30px;
  margin-right: 10px;
}

.primary {
}

.secondary {
}

.inverse {}

.brown {
  background-color: #b99e6f;
  border-bottom: 3px solid #615136;
  text-shadow: 0 1px 0 #8e7853;

  &.inverse {
    background-color: white;
    color: #b99e6f;
    text-shadow: none;
  }
}

.green {
  background-color: var(--colorForestGreen);
  border-bottom: 3px solid hsl(133, 65%, 35%);
  text-shadow: 0 1px 0 #8e7853;
}
