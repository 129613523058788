.priceCategory {
  padding: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  max-width: 400px;
  margin: 0 auto;
  flex-basis: calc(100% / 3);

  @media (width <= 780px) {
    flex-direction: column;
    text-align: center;
    padding-bottom: 30px;
  }

  @media (width <= 680px) {
    padding: 10px;
    flex-basis: calc(100% / 2);
  }

  @media (width > 780px) and (width < 1199px) {
    flex-basis: calc(100% / 2);
  }
}

.icon {
  width: 80px;
  height: 80px;
  background-color: #b9b9b9;
  color: white;
  padding: 19px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.info {
  margin-left: 20px;

  @media (width <= 780px) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.title {
  margin: 0;
  font-size: 1.8em;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  line-height: 1.2;
  color: #44c5bc;

  @media (width <= 680px) {
    font-size: 1.6em;
  }
}

.text {
  font-variant: petite-caps;
  color: hsla(189, 27%, 45%, 1);
}

.price {
  display: inline-block;
  margin-left: 0.3em;
  font-family: var(--fontFamilyLondrinaSolid);
  font-size: 1.3em;
  color: var(--colorForestGreen);

  @media (width <= 780px) {
    display: block;
    margin-left: 0;
  }
}
