.header {
}

.main {
}

.footer {
}

.openingHoursTable {
  margin: 0 auto 40px auto;

  td:first-child {
    text-align: right;
  }
}

.checkList {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;

  li {
    padding: 0 0 0 25px;
    position: relative;
    margin-bottom: 5px;

    &:before {
      position: absolute;
      left: 0;
      content: "\2713";
      color: #508a5b;
      font-size: 1.4em;
      top: -0.3em;
    }
  }
}

.columns2 {
  @media screen and (min-width: 980px) {
    columns: 2;
    column-gap: 30px;
    text-align: left;

    > *:first-child {
      margin-top: 0;
    }
  }
}

.actionButtons {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 756px) {
    flex-direction: row;

    a {
      margin: 0 10px;
    }
  }
}

.address {
  font-style: normal;
}

.priceContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 30px;
}

.haarwensenLink {
  display: block;
  max-width: 350px;
  margin: 30px auto;
}

/* 24 uur knippen actie */
.uur24Knippen {
  width: 80%;
  height: 100%;
  display: flex;

  @media screen and (max-width: 756px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    max-width: 320px;

    .uur24KnippenLeft,
    .uur24KnippenRight {
      width: 100%;
      padding: 0;
      justify-content: center;
      div {
        width: 100% !important;
      }
      img {
        margin: 0 !important;
      }

      .uur24KnippenRight {
        margin-top: 30px;
      }
    }
  }

  .uur24KnippenLeft {
    text-align: center;
    img {
      max-width: 60%;
    }
  }
}

.uur24KnippenLeft,
.uur24KnippenRight {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  img {
    max-width: 100%;
  }
}
.uur24KnippenLeft {
  width: 40%;
}
.uur24KnippenRight {
  padding-left: 30px;
  width: 60%;
}

.uur24KnippenPrice {
  position: absolute;
  right:0;
  top: -30px;
  z-index: -1;
}
