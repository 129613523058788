.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  pointer-events: none;
  font-size: 25px;
  color: white;
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--fontFamilyLondrinaSolid);
  font-weight: 300;

  &::before {
    content: "";
    background-color: rgba(18, 37, 35, 1);
    transition: transform 0.5s cubic-bezier(0.55, 0.16, 0.29, 1.19),
      opacity 0.5s cubic-bezier(0.55, 0.16, 0.29, 1.19);
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
    position: fixed;
    z-index: 0;
    width: 100vw;
    left: 0;
    top: 0;
    height: 100vh;
    transition-delay: 0.28s;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    transition: color 0.3s;
    display: inline-block;
    text-decoration: none;
    color: currentColor;
    position: relative;
    padding: 10px 10px;

    /* @see: https://tympanus.net/Development/LineMenuStyles/#Iris */
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      opacity: 0;
      border: 2px solid var(--colorLink);
      transition: transform 0.3s, opacity 0.3s;
      transition-timing-function: cubic-bezier(0.17, 0.67, 0.05, 1.29);
    }

    &::before {
      top: 0;
      left: 0;
      transform: translate3d(10px, 10px, 0);
      border-width: 2px 0 0 2px;
    }

    &::after {
      right: 0;
      bottom: 0;
      border-width: 0 2px 2px 0;
      transform: translate3d(-10px, -10px, 0);
    }

    &:hover::before,
    &:hover::after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    &:hover {
      color: var(--colorLink);
    }
  }

  li {
    transition: transform 0.5s cubic-bezier(0.71,-0.29, 0.14, 1.54),
      opacity 0.5s cubic-bezier(0.71,-0.29, 0.14, 1.54);
    transform: translateY(30px);
    opacity: 0;

    &:nth-child(1) {
      transition-delay: 0.3s;
    }
    &:nth-child(2) {
      transition-delay: 0.2s;
    }
    &:nth-child(3) {
      transition-delay: 0.1s;
    }
    &:nth-child(4) {
      transition-delay: 0s;
    }
  }
}

.loaded {
  display: flex;
}

.active {
  pointer-events: auto;
  opacity: 1;

  &::before {
    transform: scaleY(1);
    opacity: 1;
    transition-delay: 0s;
  }

  li {
    transform: translateY(0);
    opacity: 1;

    &:nth-child(1) {
      transition-delay: 0s;
    }
    &:nth-child(2) {
      transition-delay: 0.1s;
    }
    &:nth-child(3) {
      transition-delay: 0.2s;
    }
    &:nth-child(4) {
      transition-delay: 0.3s;
    }
  }
}
