.host {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  z-index: 10;
  letter-spacing: 0.1em;
  background-color: white;
  box-shadow: 0 6px 5px -6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
  transition-delay: 0.5s;

  &.darkMode {
    background-color: transparent;
    transition-delay: 0s;
    position: fixed;
    box-shadow: 0 6px 5px -6px rgba(0, 0, 0, 0);

    .hamburger {
      color: white;
    }
  }
}

.appointmentButton {
  margin: 0 20px 0 auto;
}

.hamburger {
  color: var(--colorCasal);
}

.appointmentButton,
.hamburger {
  display: none !important;
}

.logo {
  flex-basis: 300px;
  margin-right: 20px;
  color: var(--colorForestGreen);
}

.navigation {
  font-size: 19px;
  line-height: 1;
  color: var(--colorCasal);
  text-transform: uppercase;
  font-family: var(--fontFamilyLondrinaSolid);
  font-weight: 300;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    line-height: 1.6;
    margin-left: 20px;
  }

  a {
    text-decoration: none;
    color: currentColor;
    position: relative;
    padding: 5px 10px;
    transition: color 0.3s;

    /* @see: https://tympanus.net/Development/LineMenuStyles/#Iris */
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      opacity: 0;
      border: 2px solid var(--colorLink);
      transition: transform 0.3s, opacity 0.3s;
      transition-timing-function: cubic-bezier(0.17, 0.67, 0.05, 1.29);
    }

    &::before {
      top: 0;
      left: 0;
      transform: translate3d(10px, 10px, 0);
      border-width: 2px 0 0 2px;
    }

    &::after {
      right: 0;
      bottom: 0;
      border-width: 0 2px 2px 0;
      transform: translate3d(-10px, -10px, 0);
    }

    &:hover::before,
    &:hover::after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    &:hover {
      color: var(--colorLink);
    }
  }
}

.svg {
  height: inherit;
  display: block;
}

@media (width < 1080px) {
  .navigation {
    display: none;
  }

  .appointmentButton,
  .hamburger {
    display: inline-block !important;
  }
}
