.container {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 0;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
