.wordGroup {
	display: block;
	color: white;
	overflow: hidden;
	font-size: 50px;
}

.wordGroupContainer {
	display: block;
}

:global {
	.banner-title-word-group-color-brown {
		color: #bdb19a;
	}

	.banner-title-word-group-color-green {
		color: var(--colorForestGreen);
	}

	.banner-title-word-group-color-gray {
		color: gray;
	}

	.banner-title-word-group-color-yellow {
		color: #f4cd55;
	}
}
