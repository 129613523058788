.pricesPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
  background-color: transparent;
  transition: transform 0.5s cubic-bezier(0.55, 0.16, 0.29, 1);
  overflow-y: overlay;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 100px;

  &::before,
  &::after {
    content: "";
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.3s cubic-bezier(0.55, 0.16, 0.29, 1);
  }

  &::before {
    background-color: rgba(18, 37, 35, 1);
    transition-delay: 0.5s;
    transition-timing-function: cubic-bezier(0.55, 0.16, 0.29, 1);
  }

  &::after {
    transition-delay: 0.2s;
    background-color: white;
    transition-timing-function: cubic-bezier(0.55, 0.16, 0.99, 0.77);
  }
}

.active {
  pointer-events: auto;

  &::before {
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.55, 0.16, 0.99, 0.77);
    transform: translateX(0%);
  }

  &::after {
    transition-delay: 0.3s;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.55, 0.16, 0.29, 1);
    transform: translateX(0%);
  }
}

.hamburger {
  color: var(--colorCasal);
  margin: 20px;
  padding: 0;
  pointer-events: none;
  opacity: 0 !important;
  transition-delay: 0s;
  transition: opacity 0.3s ease-in-out;

  .active & {
    pointer-events: auto;
    opacity: 1 !important;
    transition-delay: 0.3s;
  }
}

.loaded {
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.priceCategoryList {
  width: 100%;
  padding: 20px;
  opacity: 0;
  transform: translateX(50px);

  .loaded & {
    transition: transform 0.4s cubic-bezier(0.54, 0.13, 0.24, 0.97),
      opacity 0.4s cubic-bezier(0.54, 0.13, 0.24, 0.97);
  }

  .active & {
    opacity: 1;
    transform: translateY(0);

    &:nth-child(1n) {
      transition-delay: 0.45s;
    }
    &:nth-child(2n) {
      transition-delay: 0.5s;
    }
    &:nth-child(3n) {
      transition-delay: 0.55s;
    }
    &:nth-child(4n) {
      transition-delay: 0.45s;
    }
    &:nth-child(5n) {
      transition-delay: 0.5s;
    }
    &:nth-child(6n) {
      transition-delay: 0.55s;
    }
  }
}

@media (width > 800px) and (width < 1280px) {
  .priceCategoryList {
    width: calc(100% / 2);
    padding: 20px;
  }
}

@media (width >= 1280px) {
  .priceCategoryList {
    width: calc(100% / 3);
    padding: 40px;
  }
}

