.priceCategoryList {
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.icon {
  width: 50px;
  height: 50px;
  background-color: #44c5bc;
  color: white;
  padding: 8px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
}

.title {
  line-height: 1.2;
  font-size: 1.8em;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  color: #44c5bc;
  margin: 0 0 0 20px;
}

.list {
  width: 100%;
  line-height: 1.4;
}

.listRow td {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  overflow: hidden;
}

.listRowText {
  font-feature-settings: "pcap";
  font-variant: petite-caps;
  font-style: italic;
  overflow-x: hidden;
  padding-right: 40px;
  width: 100%;

  span {
    background-color: white;
    padding: 0;
  }

  span::after {
    float: left;
    width: 0;
    color: #afafaf;
    white-space: nowrap;
    content: ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . "
      ". . . . . . . . . . . . . . . . . . . . ";
  }
}

.listRowPrice {
  color: var(--colorForestGreen);
  font-family: var(--fontFamilyLondrinaSolid);
  white-space: nowrap;
  vertical-align: bottom;
  text-align: right;
  line-height: 1;
  padding-left: 10px;
}

.listRowFromPrice {
  font-size: 0.6em;
  font-feature-settings: "pcap";
  font-variant: petite-caps;
  font-style: italic;
  display: block;
  color: #b5b5b5;
}

.listRowOnRequest {
    font-feature-settings: "pcap";
    font-variant: petite-caps;
    display: block;
    color: #b5b5b5;
}
