.title,
.subtitle {
  margin: 0;
  line-height: 1.2;
}

.hr {
  font-size: 0;
  margin: 20px 0;

  &::before,
  &::after {
    background-color: currentColor;
    content: '';
    display: inline-block;
    height: 1px;
    padding: 0 5px;
    vertical-align: middle;
    width: 20%;
    margin: 0 10px;
    opacity: 0.3;
  }
}

.icon {
  color: currentColor;
  display: inline-block;
  height: 22px;
  vertical-align: middle;
  width: 22px;
  opacity: 0.5;
}

.root {
  color: var(--colorHeaderGroupDelimiter);
  text-align: center;
}
