@import url("https://fonts.googleapis.com/css?family=Lato:300|Londrina+Solid:300,400");
@import "./selectors";

:--heading {
  font-family: var(--fontFamilyLondrinaSolid);
  font-weight: 300;
  letter-spacing: 1.2px;
}

:global {
  html.block-scroll body {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
}

:any-link {
  color: var(--colorLink);
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 1.5em;
}

canvas {
  display: block;
  pointer-events: none;
}

body {
  color: var(--colorBodyText);
  font-family: var(--fontFamilyLato);
  font-feature-settings: "liga", "kern";
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1.6;
  margin: 0;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}
