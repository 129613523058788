.countdownContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .left {
    text-align: center;
    margin-right: 30px;
  }

  .right {
    text-align: center;
    margin-left: 30px;
  }
}
.days {

}
.hours {
  margin-top: 30px;
}
.minutes {

}
.seconds {
margin-top: 30px;
}
.digit {
  font-family: var(--fontFamilyLondrinaSolid);
  font-weight: 400;
  font-size: 70px;
  line-height: 1;
  color: #44c5bc;
}
.timeIndicator {
  text-transform: uppercase;
}
